import { BrandIcon, IconName as BrandIconName } from './brand';
import { LightIcon, IconName as LighIconName } from './light';
import { RegularIcon, IconName as RegularIconName } from './regular';

import { GenericIconProps } from './types';

export type IconName = BrandIconName | LighIconName | RegularIconName;

export const Icon = (iconProps: GenericIconProps<BrandIconName | LighIconName | RegularIconName>) => {
	if (!iconProps.icon.type) {
		console.error("type not provided for", iconProps.icon.icon);
	}

	if (iconProps.icon.type === 'fab') {
		return <BrandIcon {...iconProps} />
	} else if (iconProps.icon.type === 'far') {
		return <RegularIcon {...iconProps} />
	} else { // made else instead of elseif as the missing icon pack's item icon might exist in light too, meaning it could work as a fallback in some scenarios
		return <LightIcon {...iconProps} />
	}
}
