import { SetIconProps } from './types';

import {
	faTwitter,
	faFacebook,
	faFacebookF,
	faYoutube,
	faInstagram,
	IconDefinition,
	IconName,
} from "@fortawesome/free-brands-svg-icons";

import { convertIconToDefinition } from './util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum StaticIcon {
	Twitter,
	FacebookFilled,
	Facebook,
	YouTube,
	Instagram,
	// XTwitter,
};

export const iconStrings: Map<IconName, StaticIcon> = new Map([
	['twitter', StaticIcon.Twitter],
	['facebook-f', StaticIcon.FacebookFilled],
	['facebook', StaticIcon.Facebook],
	['youtube', StaticIcon.YouTube],
	['instagram', StaticIcon.Instagram],
	// 'x-twitter': StaticIcon.XTwitter,
]);

export const icons = {
	[StaticIcon.Twitter]: faTwitter,
	[StaticIcon.Facebook]: faFacebook,
	[StaticIcon.FacebookFilled]: faFacebookF,
	[StaticIcon.YouTube]: faYoutube,
	[StaticIcon.Instagram]: faInstagram,
	// [StaticIcon.XTwitter]: faXTwitter,
}

export const getIconDefinition = (iconClass: IconName): IconDefinition => {
	const enumVal = iconStrings.get(iconClass);

	return icons[enumVal];
}

export const BrandIcon = ({ icon, style, className = "", fixedWidth = false, spin = false }: SetIconProps<IconName>) => {
	const iconDefinition = convertIconToDefinition<IconName, IconDefinition>(icon, getIconDefinition);

	return (
		<FontAwesomeIcon
			icon={iconDefinition}
			size={icon.size || "lg"}
			className={className}
			style={style}
			fixedWidth={fixedWidth}
			spin={spin}
		/>
	);
}

export type { IconName };