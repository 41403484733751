import { Icon, IconName } from "../Shared/Icons/generic";
import { Icon as IconType } from "../Shared/Icons/types";

import TwitterXIcon from "./XIcon";

export const SocialItem = ({ socialItem }: { socialItem: { url: { url: string, target: string }, icon: IconType<IconName> } }) => {
	const isTwitter = socialItem.icon.icon.indexOf('twitter') !== -1;

	return socialItem.url ? (
		<a
			className="footer_social_icon grey-text text-lighten-1 "
			href={socialItem.url.url}
			target={`${socialItem.url.target}`}
		>
			{isTwitter && <TwitterXIcon />}
			{!isTwitter && <Icon icon={socialItem.icon} />}
		</a>
	) : null;
}