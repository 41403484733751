import { Fragment } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { SiteContext } from "../Contexts/SiteContext";
import { hasProperty } from "../../utils/validate";
import { SocialItem } from "./SocialItem";

const Accordion = dynamic(() => import("../Shared/Accordion/Accordion"));

const FooterLink = (item, i) => {
	return (
		<div className="col s2 m2" key={item.id}>
			<h5 className="white-text footerHeaderTitle">
				{item.title}
			</h5>
			{item.children.map(nav => {
				return nav.type === "Entry" ? (
					<Link key={nav.id} href={`/${nav.uri}`}>
						<a className="footer_shopping_tools two-zero-zero">{nav.title}</a>
					</Link>
				) : (
					<Fragment key={nav.id}>
						{nav.url != null && (
							<a
								className="footer_shopping_tools two-zero-zero"
								href={nav.url}
								target={
									nav.newWindow ? "_blank" : "_self"
								}
								title={nav.title}
							>
								{nav.title}
							</a>)
						}
					</Fragment>
				)
			})
			}
		</div>
	);
};

const Logo = ({ hrc }) => {
	return hasProperty(hrc, "logo") && hrc.logo != null ? (
		<div className="footerHeaderImg">
			<img
				src={hrc.logo}
			/>
		</div>
	) : <></>
};

const Links = ({ hrc }) => {
	return hasProperty(hrc, "links") && (
		hrc.links.map((link, index) => {
			return link.type == "Entry" ? (
				<Link key={index} href={`/${link.uri}`}>
					<a className="footer_shopping_tools two-zero-zero">{link.text}</a>
				</Link>
			) : (
				<a
					key={index}
					className="footer_shopping_tools two-zero-zero"
					href={link.url}
					target={`${link.target}`}
					title={link.text}
				>
					{link.text}
				</a>
			)
		})
	)
}

const Social = ({ hrc }) => {
	return hasProperty(hrc, "social") && (
		<div className="hrc-social">
			{hrc.social.map((socialItem, i) => {
				return (
					<Fragment key={`a-${i}`}>
						<SocialItem socialItem={socialItem} />
					</Fragment>
				);
			})}
		</div>
	)
};

const GeneralFooter = ({ footer: { links, stayInformed, hrc = null, copy, social } = {} }) => {
	return (
		<SiteContext.Consumer>{(context) => {
			const { deviceType } = context;

			return (
				<footer className="page-footer grey darken-4">
					{deviceType === 'desktop' ? (
						<div className="customContainer largeFooterDesktop">
							<div className="row">
								{links && links.map(FooterLink)}
								{hrc && (
									<div className="col s2 m2">
										<Logo hrc={hrc} />
										<Links hrc={hrc} />
										<Social hrc={hrc} />
									</div>
								)}
								<div className="col s2 m2 right">
									{stayInformed && stayInformed.title !== null &&
										<h5 className="white-text">
											{stayInformed.title ? stayInformed.title : 'Receive updates about Honda'}
										</h5>
									}
									{stayInformed && stayInformed.button !== null &&
										<Link href="/honda-updates">
											<a
												className="waves-effect waves-light btn-large buttonRed hButton hover-black-text"
												style={{ fontSize: "13px" }}

												title={stayInformed.button ? stayInformed.button : 'stay informed'}
											>
												{stayInformed.button ? stayInformed.button : 'stay informed'}
											</a>
										</Link>
									}
									{deviceType != 'mobile' &&
										<div className="col l12 m12 s12 no-padding">
											<div className="footer-social-wrapper">
												<div className="footer_social_icons">
													{social && social.map((socialItem, i) => {
														return (
															<Fragment key={`c-${i}`}>
																<SocialItem
																	socialItem={socialItem}
																	key={`social-${i}`}
																/>
															</Fragment>
														);
													})}
												</div>
											</div>
										</div>
									}

								</div>
							</div>
						</div>
					) : (
						<div className="customContainer largeFooterMobile">
							<div className="row">
								<div className="col s12 m12">
									<div className="updateHeading center">
										<h2>{stayInformed && stayInformed.title ? stayInformed.title : 'Receive updates about Honda'}</h2>
									</div>
									<div className="updateButton">
										<Link href="/honda-updates">
											<a
												className="waves-effect waves-light btn-large hButton buttonRed hover-black-text"
												title="Stay Informed"
											>
												{stayInformed && stayInformed.button ? stayInformed.button : 'stay informed'}
											</a>
										</Link>
									</div>

									{hrc && (
										<div className="hrc" data-device={`${deviceType}`}>
											<div className="col s6 m6">
												<div>
													{hasProperty(hrc, "logo") && hrc.logo != null ? (
														<div className="footerHeaderImg">
															<img
																src={hrc.logo}
															/>
														</div>
													) : null
													}
													{hasProperty(hrc, "links") && (
														hrc.links.map((link, index) => {
															return link.type == "Entry" ? (
																<Link key={index} href={`/${link.uri}`}>
																	<a className="footer_shopping_tools two-zero-zero">{link.text}</a>
																</Link>
															) : (
																<a
																	key={index}
																	className="footer_shopping_tools two-zero-zero"
																	href={link.url}
																	target={`${link.target}`}
																	title={link.text}
																>
																	{link.text}
																</a>
															)
														})
													)}
												</div>
											</div>
											<div className="col s6 m6">
												{hasProperty(hrc, "social") && (
													<div className="hrc-social" data-device={`${deviceType}`}>
														{hrc.social.map((socialItem, i) => {
															return (
																<Fragment key={`b-${i}`}>
																	<SocialItem
																		socialItem={socialItem}
																		key={`social-${i}`}
																	/>
																</Fragment>
															);
														})}
													</div>
												)}
											</div>

										</div>
									)}

									{links && links.map((item, i) => {
										return (
											<Accordion
												title={item.title}
												id={`mobile-${item.id}`}
												key={`mobile-acc-${item.id}`}
											>
												<>
													{item.children.map(nav => {
														return nav.type == "Entry" ?
															(
																<Link href={`/${nav.uri}`} key={`mobile-${nav.id}`}>
																	<a
																		className="footer_shopping_tools two-zero-zero"
																		target={
																			nav.newWindow
																				? "_blank"
																				: "_self"
																		}
																		title={nav.title}>
																		{nav.title}
																	</a>
																</Link>
															) : (
																<a
																	key={`mobile-${nav.id}`}
																	href={nav.url}
																	className="footer_shopping_tools two-zero-zero"
																	target={
																		nav.newWindow
																			? "_blank"
																			: "_self"
																	}
																	title={nav.title}>
																	{nav.title}
																</a>
															)
													})
													}
												</>
											</Accordion>
										);
									})}
								</div>
							</div>
						</div>
					)
					}
					<div className="customContainer">
						<div className="row mb0px">
							{deviceType != 'desktop' &&
								<div className="col l3 m12 s12 center">
									<div className="footer-social-wrapper">
										<div className="footer_social_icons">
											{social && social.map((socialItem, i) => {
												return (
													<Fragment key={`c-${i}`}>
														<SocialItem
															socialItem={socialItem}
															key={`social-${i}`}
														/>
													</Fragment>
												);
											})}
										</div>
									</div>
								</div>
							}

							<div className="col l9 m12 s12 left">
								<div className="footer-copyright">
									<div className="footer_social_icons">
										{copy && copy.map((copyItem, i) => {
											if (copyItem.component === 'addCopy') {
												return (
													<span key={`CR-${i}`}>
														{copyItem.title} {copy.length - 1 !== i ? " | " : null}
													</span>
												);
											} else {
												return copyItem.link && ( // first find link property is available
													copyItem.link.type == "Entry" ? (
														<Fragment key={`CR-${i}`}>
															<Link href={`/${copyItem.link.uri}`}>
																<a
																	className="default"
																	title={copyItem.link.text}
																	target={copyItem.link.target}>
																	{copyItem.link.text}
																</a>
															</Link>
															{copy.length - 1 !== i ? " | " : null}
														</Fragment>

													) : (
														<Fragment key={`CR-${i}`}>
															<a
																className="default"
																href={copyItem.link.url}
																title={copyItem.link.text}
																target={copyItem.link.target}
															>
																{copyItem.link.text}
															</a>
															{copy.length - 1 !== i ? " | " : null}
														</Fragment>
													)
												)
											}
										})}
									</div>
								</div>
							</div>

						</div>
					</div>
				</footer>
			);
		}}
		</SiteContext.Consumer>
	);
};

export default GeneralFooter;